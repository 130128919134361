import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseCoreApiService } from '@autobot/core';

@Component({
  selector: 'confirm-dialog-search',
  template: `
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="black">close</mat-icon>
    </button>
    <h2 mat-dialog-title fxLayoutAlign="start center">{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
      <h3>{{ message }}</h3>
    </mat-dialog-content>
    <div fxLayout="column" class="p-3" fxLayoutGap="2%">
      <mat-card-content fxFlex="80">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
          <div fxFlex="50" class="custom-input">
            <mat-label>Phone System ID</mat-label>
            <input
              matInput
              #phoneSystemId
              maxlength="20"
              matInput
              placeholder="Phone System ID"
              (blur)="onChangeEvent(phoneSystemId.value)"
              (input)="onChangePhoneSystemID()"
              formControlName="phoneSystemId"
            />
            <div style="position: absolute;">
              <mat-hint style="position: relative; display: block;" *ngIf="phoneSystemId.value?.length > 10"
                >{{ phoneSystemId.value?.length || 0 }}/20</mat-hint
              >
              <mat-hint style="position: relative; display: block;" style="color: red;" align="start">
                {{ phoneSystemMessage }}</mat-hint
              >
              <mat-hint
                *ngIf="phoneSystemId.value?.length < 12 && phoneSystemId?.value"
                style="color: red; position: relative; display: block;"
                align="start"
                >The Phone System ID must be at least 12 characters.</mat-hint
              >
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
    <mat-dialog-actions>
      <div data-group="buttongroup1" data-id="OpenCase"
        fxLayout="row"
        fxLayoutAlign="end"
        style="text-align:center;width:100%"
      >
        <button data-id="OpenWithoutPhoneSystemId"
          mat-raised-button
          color="primary"
          mat-dialog-close="skip"
        >
          {{ cancelButtonText }}
        </button>
        <button data-id="OpenWithPhoneSystemId"
          mat-raised-button
          color="primary"
          [disabled]="!this.isDuplicatephoneSystem || phoneSystemId.value?.length < 12 "
          [mat-dialog-close]="phoneSystemId.value"
        >
          {{ acceptButtonText }}
        </button>
      </div>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .close-button {
        float: right;
        top: -10px;
        right: -10px;
      }

      ::ng-deep .icon-outside .close-button {
        float: right;
        top: -52px;
        right: -52px;
      }

      /* ::ng-deep .icon-outside .mat-dialog-container {
 overflow: unset
} */
    `,
  ],
})
export class ConfirmDialogSearchComponent {
  title: string;
  message!: string;
  isContunue: true;
  acceptButtonText = 'YES';
  cancelButtonText = 'CANCEL';
  form: FormGroup;
  phoneSystemMessage: string;
  isDuplicatephoneSystem = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CaseCoreApiService
  ) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
  }

  onChangePhoneSystemID() {
    this.isDuplicatephoneSystem = false;
  }

  onChangeEvent(phoneSystemId) {
    this.phoneSystemMessage = '';
    this.validatePhoneId(phoneSystemId);
  }
  validatePhoneId(phoneSystemId: string) {
    if (phoneSystemId.length > 11)
        this.validatePhoneSYstemDuplicateCheck(phoneSystemId);
  }
  validatePhoneSYstemDuplicateCheck(phoneSystemId) {
    this.service
      .checkduplicatePhoneSystem(phoneSystemId)
      .subscribe((result) => {
        if (result && result['calls'].length > 0) {
          this.phoneSystemMessage =
            'This Phone System ID already exist';
          this.isDuplicatephoneSystem = false;
        } else {
          this.phoneSystemMessage = '';
          this.isDuplicatephoneSystem = true;
        }
      });
  }

  // navigate(id) {
  //  // if (dest == 'editor') this.router.navigate(['nfr/case-editor/edit', id]);
  //  this.router.navigate(['nfr/case', id]);
  // }

}
